@import 'styles/variables';

.btnTrigger {
  width: 40px;
  height: 40px;
}

.base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $white;
  animation: slideUpAndFade .4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

// Input section

.inputSection {
  padding: 12px;
  border-bottom: 1px solid $stroke_hover;
}

.inputWrapper {
  position: relative;
  height: 40px;
  padding: 8px;

  border-radius: 12px;
  border: 1px solid #e6e6e6;
}
.inputLabel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.input {
  position: relative;
  flex-grow: 1;
  color: $text-black;
  font-size: 13px;

  &::placeholder {
    color: $black-40;
  }
}
.btnInputReset {
  position: relative;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnClose {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #e6e6e6;
}

// List section

.listContainer {
  display: flex;
  flex-flow: column;
  overflow: auto;
}

// Adaptive

@media (min-width: $tablets) {
  .btnTrigger {
    display: none;
  }
}

