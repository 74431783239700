@import 'styles/variables';

.base {
  padding: 8px 12px;
  max-height: 70px;

  transition:
          padding .2s .1s ease-in ,
          max-height .3s ease-in,
          background-color .15s ease-in;
  &:hover {
    background-color: $gray-light-color;
  }

  &:hover, &:focus {
    .buttonRemove {
      opacity: .6;
    }
  }
}
.isRemoved {
  max-height: 0;
  padding: 0 12px;
  overflow: hidden;
  pointer-events: none;
}

.logoWrapper {
  position: relative;
  width: 40px;
  height: 40px;
}
.matchLogos {
  :first-child {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  :last-child {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.bookmakerLogo {
  width: 40px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
    max-width: 33px;
    max-height: 11px;
  }
}

.title {
  max-height: 36px;
  overflow: hidden;

  color: $text-black;
  font-size: 15px;
  line-height: 18px;
}

.description {
  color: $black-60;
  font-size: 13px;
  line-height: 16px;
}

.textDivider {
  width: 3px;
  height: 3px;

  border-radius: 1px;
  background-color: $black-40;
}

.addonAfter {
  position: relative;
}

.buttonRemove {
  width: 24px;
  height: 24px;

  transition: opacity .15s ease-in;
  opacity: 0;

  &:hover, &:focus {
    opacity: 1 !important;
  }

  &:disabled {
    opacity: .4;
    pointer-events: none;
  }
}

.badge {
  padding: 5px 4px;
  border-radius: 6px;

  font-size: 11px;
  line-height: 14px;
  font-weight: bold;
  text-transform: uppercase;

  color: $gray-color;
  background-color: $black-20;
}
.badgeLive {
  color: $red-secondary;
  background-color: rgba($red-secondary, .15);
}

// Adaptive

@media (max-width: $tablets) {
  .buttonRemove {
    opacity: 1;
  }
}
