html,
body {
  padding: 0;
  margin: 0;
  font-size:14px;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-family: inherit;
  /* Fix default blue button tap */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header {
  position: relative;
  top: 0;
  background: linear-gradient(156.57deg, #45526D -58.71%, #252E3F 87.01%);
}

.header__top{
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 56px;
}

.header__top-wrapper{
  border-bottom: 1px solid #FFFFFF33;
}